import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListAll = () => {
    return service.get(ApiUrl.Introduce_GetListAll).then(res => { return res }).catch(err => { throw err });
}

export const GetDetail = (id) => {
    return service.get(ApiUrl.Introduce_GetDetail.replace("{id}",id)).then(res => { return res }).catch(err => { throw err });
}

export const Create = (body) => {
    return service.post(ApiUrl.Introduce_Create, body).then(res => { return res }).catch(err => { throw err });
}

export const Update = (body) => {
    return service.post(ApiUrl.Introduce_Update, body).then(res => { return res }).catch(err => { throw err });
}