import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

//--- Material Control
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Select,
    MenuItem,
    FormControl,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

//--- Action
import * as categoryParentAction from "../../../redux/store/category-parent/category-parent.store";
import "./add-category-parent.scss";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

export default function AddCategoryParent(props) {
    const classes = useStyles();

    const {
        isOpen,
        onClose,
        onSuccess,
        GetListCategoryParent,
        categoryParentModels,
        rowsPerPage,
        showLoading,
    } = props;

    const [listCategoryParent, setListCategoryParent] = useState();

    useEffect(() => {
        categoryParentAction
            .GetListCategoryParent(1, 2147483647, "", "", 0)
            .then((res) => {
                if (res && res.content) {
                    let listparent = res.content.items.filter(x => !x.parent);
                    listparent.push({ id: 0, category_parent_name: "Không chọn cấp trên" });
                    setListCategoryParent(listparent);
                }
            })
            .catch((err) => {
            });
        
    }, []);

    const { register, handleSubmit, errors, setValue, control } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
            return;
        }
        showLoading(true);
        categoryParentAction
            .CreateCategoryParent(data)
            .then((result) => {
                if (result && result.content && result.content.status === true) {
                    GetListCategoryParent(1, rowsPerPage);
                    showLoading(false);
                    onSuccess();
                    ShowNotification(
                        viVN.Success.AddCategoryParent,
                        NotificationMessageType.Success
                    );
                }
            })
            .catch((err) => {
                showLoading(false);
                onSuccess();
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            });
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Thêm quận/huyện</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <DialogContent className="pt-4 pb-2">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <label className="text-dark">
                                        Tên nhóm layer<span className="required"></span>
                                    </label>
                                    <TextField
                                        type="text"
                                        name="categoryParentName"
                                        className="w-100"
                                        inputProps={{ maxLength: 150 }}
                                        inputRef={register({ required: true })}
                                        error={errors.categoryParentName && errors.categoryParentName.type === "required"}
                                    />
                                    {errors.categoryParentName && errors.categoryParentName.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                    {errors.categoryParentName && errors.categoryParentName.type === "maxLength" && (
                                        <span className="error">Trường này không quá 150 ký tự</span>
                                    )}
                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                    <FormControl
                                        fullWidth
                                    >
                                        <label className="text-dark">
                                            Nhóm layer cấp trên
                                        </label>
                                        <Controller
                                            name="parentId"
                                            control={control}
                                            as={
                                                <Select>
                                                    {listCategoryParent && listCategoryParent.length > 0 ? (
                                                        listCategoryParent.map((item) => (
                                                            <MenuItem value={item.id}>{item.category_parent_name}</MenuItem>
                                                        ))
                                                    ) : (
                                                            <MenuItem value="">
                                                                Không có nhóm layer nào
                                                            </MenuItem>
                                                        )}
                                                </Select>
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <label className="text-dark">
                                        Màu
                                    </label>
                                    <TextField
                                        type="text"
                                        name="color"
                                        className="w-100"
                                        inputRef={register}
                                        inputProps={{ maxLength: 7 }}
                                    />
                                    {errors.color && errors.color.type === "maxLength" && (
                                        <span className="error">Trường này không quá 7 ký tự</span>
                                    )}
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <label className="text-dark">
                                        Sắp xếp
                                    </label>
                                    <TextField
                                        type="text"
                                        name="order"
                                        className="w-100"
                                        inputRef={register({ required: true })}
                                        onChange={(e) =>
                                            setValue(
                                                "order",
                                                e.target.value.replace(/[^0-9]/g, "")
                                            )
                                        }
                                        error={
                                            errors.order && errors.order.type === "required"
                                        }
                                    />
                                    {errors.order && errors.order.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions className="border-top">
                        <Button
                            type="submit"
                            onClick={onClose}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
            </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Lưu
            </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
