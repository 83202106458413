export default function CreateDefaultMapData(planningId, mapId = null, defaultCordinate = null) {
    return ({
        "id": mapId || 0,
        "planing_id": planningId,
        "name": "",
        "z_index": 0,
        "zoom": defaultCordinate ? defaultCordinate.zoom: 9,
        "min_zoom": defaultCordinate ? defaultCordinate.min_zoom: 8,
        "max_zoom": defaultCordinate ? defaultCordinate.max_zoom: 20,
        "projection": "EPSG:4326",
        "extent": defaultCordinate ? defaultCordinate.extent : "105.963670,10.072650, 110.718837, 13.489382",
        "center": defaultCordinate ? defaultCordinate.center : "108.861014, 11.695404",
        "map_setting": [
            {
                "id": 0,
                "map_id": mapId || 0,
                "name": "MAP",
                "type_map": "MAP",
                "layer_categories": [],
                "base_maps": [
                    {
                        "baseMapSettingModel": {
                            "id": 2,
                            "layer_type": "GOOGLE",
                            "name": "Bản đồ nền giao thông",
                            "status": false,
                            "url": "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
                        },
                        "base_map_setting_id": 2,
                        "id": 0,
                        "map_setting_id": 0,
                        "url": "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
                        "view_default": true,
                        "z_index": 0,
                        "name": "Bản đồ google"
                    }
                ]
            },
            {
                "id": 0,
                "map_id": mapId || 0,
                "name": "Layer",
                "type_map": "LAYER",
                "layer_categories": [],
                "base_maps": []
            }
        ]
    })
}