/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//--- Action
import * as introduceAction from "../../../redux/store/introduces/introduce.store";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import * as viVN from "../../../language/vi-VN.json";

//--- Material Control
import {
    DialogActions,
    TextareaAutosize,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    makeStyles,
    Typography,
    IconButton,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
    NotificationMessageType,
    APIUrlDefault,
} from "../../../utils/configuration";
import * as appActions from "../../../core/app.store";

//--- Styles
import "../introduce.scss";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function EditIntroduce(props) {
    const classes = useStyles();

    const {
        isOpen,
        onClose,
        onSuccess,
        introduceId,
        GetListAll,
    } = props;

    const [introduceModel, setIntroduceModel] = useState();
    const [content, setContent] = useState();
    const [xmin, setXmin] = useState();

    useEffect(() => {
        introduceAction
            .GetDetail(introduceId)
            .then((res) => {
                if (res && res.content) {
                    setIntroduceModel(res.content);
                    setContent(res.content.content);
                    setXmin(res.content.xmin);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
            return;
        }

        const updateModel = { ...data, content: content, id: introduceId, xmin: xmin };
        console.log(data);
        introduceAction
            .Update(updateModel)
            .then((result) => {
                if (result) {
                    GetListAll();
                    onSuccess();
                    ShowNotification(
                        viVN.Success.IntroduceEditSuccess,
                        NotificationMessageType.Success
                    );
                }
            })
            .catch((err) => {
                onSuccess();
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            });
    };

    const handleChangeContent = (editorContent) => {
        clearErrors(["editorContent"]);
        if (editorContent === "<p><br></p>") {
            setError("editorContent", { type: "required" });
            setContent("");
        } else {
            clearErrors("editorContent");
            setContent(editorContent);
        }
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Chỉnh sửa bài giới thiệu</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    {introduceModel && (
                        <DialogContent className="pt-4 pb-2">
                            <div className="form-group">
                                <label className="text-dark">
                                    Loại<span className="required"></span>
                                </label>
                                <TextField
                                    inputRef={register({ required: true })}
                                    name="keyWord"
                                    error={
                                        errors.keyWord &&
                                        (errors.keyWord.type === "required" ||
                                            errors.keyWord.type === "maxLength")
                                    }
                                    fullWidth
                                    type="text"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    className="form-control"
                                    defaultValue={introduceModel.keyWord}
                                    onChange={(e) =>
                                        setValue("keyWord", e.target.value)
                                    }
                                />
                                {errors.keyWord && errors.keyWord.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.keyWord && errors.keyWord.type === "maxLength" && (
                                    <span className="error">Trường này không quá 20 ký tự</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="text-dark">
                                    Tiêu đề<span className="required"></span>
                                </label>
                                <TextField
                                    inputRef={register({ required: true })}
                                    name="title"
                                    error={
                                        errors.title &&
                                        (errors.title.type === "required" ||
                                            errors.title.type === "maxLength")
                                    }
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    defaultValue={introduceModel.title}
                                    onChange={(e) => 
                                        setValue("title", e.target.value)
                                    }
                                />
                                {errors.title && errors.title.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.title && errors.title.type === "maxLength" && (
                                    <span className="error">Trường này không quá 200 ký tự</span>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="text-dark">Nội dung</label>
                                <SunEditor
                                    enableToolbar={true}
                                    showToolbar={true}
                                    setContents={introduceModel.content}
                                    setOptions={{
                                        height: 300,
                                        buttonList: [
                                            [
                                                "undo",
                                                "redo",
                                                "font",
                                                "fontSize",
                                                "formatBlock",
                                                "paragraphStyle",
                                                "blockquote",
                                                "bold",
                                                "underline",
                                                "italic",
                                                "strike",
                                                "subscript",
                                                "superscript",
                                                "fontColor",
                                                "hiliteColor",
                                                "textStyle",
                                                "removeFormat",
                                                "outdent",
                                                "indent",
                                                "align",
                                                "horizontalRule",
                                                "list",
                                                "lineHeight",
                                                "table",
                                                "link",
                                                "image",
                                                "video",
                                                "audio",
                                                "fullScreen",
                                                "showBlocks",
                                                "codeView",
                                            ],
                                        ],
                                    }}
                                    onChange={handleChangeContent}
                                    onBlur={(event, editorContents) => handleChangeContent(editorContents)}
                                />
                            </div>
                        </DialogContent>
                    )}

                    <DialogActions className="border-top">
                        <Button
                            type="button"
                            onClick={onClose}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
            </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Lưu
            </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(EditIntroduce);
