import React, { useState } from "react";
import { useForm } from "react-hook-form";

//--- Styles
import "../introduce.scss";

//--- Material Control
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

//--- Action
import * as introduceAction from "../../../redux/store/introduces/introduce.store";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

export default function AddIntroduce(props) {
    const classes = useStyles();

    const {
        isOpen,
        onClose,
        onSuccess,
        GetListAll,
    } = props;

    const [content, setContent] = useState();

    const { register, handleSubmit, setError, errors, clearErrors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onChangeContent = (editorContent) => {
        clearErrors(["editorContent"]);
        if (editorContent === "<p><br></p>") {
            setError("editorContent", { type: "required" });
            setContent("");
        } else {
            clearErrors("editorContent");
            setContent(editorContent);
        }
    };

    const onSubmit = (data) => {
        if (!data) {
            return;
        }
        const introduceModel = { ...data, content: content };
        // onSuccess();
        introduceAction
            .Create(introduceModel)
            .then((result) => {
                if (result) {
                    GetListAll();
                    onSuccess();
                    ShowNotification(
                        viVN.Success.IntroduceAddSuccess,
                        NotificationMessageType.Success
                    );
                }
            })
            .catch(
                (err) => viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Thêm bài giới thiệu</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <DialogContent className="pt-4 pb-2">
                        <div className="form-group">
                            <label className="text-dark">
                                Loại<span className="required"></span>
                            </label>
                            <TextField
                                name="keyWord"
                                error={
                                    errors.keyWord &&
                                    (errors.keyWord.type === "required" ||
                                        errors.keyWord.type === "maxLength")
                                }
                                fullWidth
                                type="text"
                                className="form-control"
                                inputRef={register({ required: true, maxLength: 20 })}
                                onChange={(e) =>
                                    setValue("keyWord", e.target.value)
                                }
                            />
                            {errors.keyWord && errors.keyWord.type === "required" && (
                                <span className="error">Trường này là bắt buộc</span>
                            )}
                            {errors.keyWord && errors.keyWord.type === "maxLength" && (
                                <span className="error">Trường này không quá 20 ký tự</span>
                            )}
                        </div>
                        <div className="form-group">

                            <label className="text-dark">
                                Tiêu đề<span className="required"></span>
                            </label>
                            <TextField
                                name="title"
                                error={
                                    errors.title &&
                                    (errors.title.type === "required" ||
                                        errors.title.type === "maxLength")
                                }
                                fullWidth
                                type="text"
                                className="form-control"
                                inputRef={register({ required: true, maxLength: 200 })}
                                onChange={(e) => setValue("title", e.target.value)
                                }
                            />
                            {errors.title && errors.title.type === "required" && (
                                <span className="error">Trường này là bắt buộc</span>
                            )}
                            {errors.title && errors.title.type === "maxLength" && (
                                <span className="error">Trường này không quá 200 ký tự</span>
                            )}
                        </div>
                        <div className="form-group">
                            <label className="text-dark">Nội dung</label>
                            <SunEditor
                                enableToolbar={true}
                                showToolbar={true}
                                imageAccept=".jpg,.jpeg,.png,.gift,.svg"
                                imageUploadUrl="https://localhost:44348/api/admin/File/UploadImage"
                                setOptions={{
                                    height: 300,
                                    buttonList: [
                                        [
                                            "undo",
                                            "redo",
                                            "font",
                                            "fontSize",
                                            "formatBlock",
                                            "paragraphStyle",
                                            "blockquote",
                                            "bold",
                                            "underline",
                                            "italic",
                                            "strike",
                                            "subscript",
                                            "superscript",
                                            "fontColor",
                                            "hiliteColor",
                                            "textStyle",
                                            "removeFormat",
                                            "outdent",
                                            "indent",
                                            "align",
                                            "horizontalRule",
                                            "list",
                                            "lineHeight",
                                            "table",
                                            "link",
                                            "image",
                                            "video",
                                            "audio",
                                            "fullScreen",
                                            "showBlocks",
                                            "codeView",
                                        ],
                                    ],
                                }}
                                onChange={onChangeContent}
                                onBlur={(event, editorContents) => onChangeContent(editorContents)}

                            />
                        </div>
                    </DialogContent>

                    <DialogActions className="border-top">
                        <Button
                            type="submit"
                            onClick={onClose}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                    </Button>
                    <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Lưu
                    </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
