import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();
export const GetListCategoryParent = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", name = "", isDelete=0, parentId=undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    name && params.append("name", name);
    params.append("isDelete", isDelete);
    parentId && params.append("parentId", parentId);
    return service.get(ApiUrl.LayerCategoryParent_GetListAll, params).then((res) => { return res }).catch(err => { throw err });
}
export const GetListHierarchyCategoryParent = (pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "", name = "", isDelete = 0, parentId = undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    name && params.append("name", name);
    params.append("isDelete", isDelete);
    parentId && params.append("parentId", parentId);
    return service.get(ApiUrl.LayerCategoryParent_GetListAllHierarchy, params).then((res) => { return res }).catch(err => { throw err });
}
export const GetDetailCategoryParent = (id) => {
    return service.get(ApiUrl.LayerCategoryParent_GetDetail.replace("{id}", id)).then(res => { return res }).catch(err => { throw err });
}

export const CreateCategoryParent = (body) => {
    return service.post(ApiUrl.LayerCategoryParent_Create, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateCategoryParent = (body) => {
    return service.post(ApiUrl.LayerCategoryParent_Update, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteCategoryParent = (id) => {
    return service.postParams(ApiUrl.LayerCategoryParent_Delete.replace("{id}",id)).then(res => { return res }).catch(err => { throw err });
}
export const RestoreCategoryParent = (id) => {
    return service.postParams(ApiUrl.LayerCategoryParent_Restore.replace("{id}", id)).then(res => { return res }).catch(err => { throw err });
}
export const EmptyTrash = () => {
    return service.post(ApiUrl.LayerCategoryParent_EmptyTrash).then(res => { return res }).catch(err => { throw err });
}