import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Configs } from "../../../common/config";
import dateformat from "dateformat";

//--- Material Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";

//--- Material Icon
import EditIcon from "@material-ui/icons/Edit";

//--- Material Control
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: window.outerHeight - 365,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const headCells = [
    { id: "keyWord", hideSortIcon: true, label: "Loại" },
    { id: "title", hideSortIcon: true, label: "Tiêu đề" },
    { id: "createdBy", hideSortIcon: false, label: "Người tạo" },
    { id: "createdDate", hideSortIcon: false, label: "Ngày tạo" },
    { id: "modifiedBy", hideSortIcon: false, label: "Người sửa" },
    { id: "modifiedDate", hideSortIcon: false, label: "Ngày sửa" },
    { id: "actions", hideSortIcon: true, label: "" },
];

function EnhancedTableHead(props) {
    const { classes,onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        className="pt-3 pb-3 text-nowrap"
                    >
                        <TableSortLabel
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={headCell.hideSortIcon ? true : false}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    //onRequestSort: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function ListIntroduce(props) {
    const {
        editAction,
        introduceModels,
        GetListAll,
    } = props;

    //--- Config table
    const classes = useStyles();

    
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} size="small" stickyHeader>
                        <EnhancedTableHead
                            classes={classes}
                            rowCount={introduceModels.length}
                        />
                        <TableBody>
                            {introduceModels && introduceModels.length > 0 ? (
                                introduceModels.map((row, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.id}>
                                            <TableCell>{row.keyWord}</TableCell>
                                            <TableCell>{row.title}</TableCell>
                                            <TableCell>{row.created_by}</TableCell>
                                            <TableCell>{dateformat(row.created_date, "dd/mm/yyyy")}</TableCell>
                                            <TableCell>{row.modified_by}</TableCell>
                                            <TableCell>{dateformat(row.modified_date, "dd/mm/yyyy")}</TableCell>
                                            <TableCell align="right" className="text-nowrap">
                                                <Tooltip title="Sửa">
                                                    <IconButton
                                                        aria-label="edit"
                                                        onClick={() => editAction(row.id)}
                                                    >
                                                        <EditIcon className="text-primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                    <TableRow hover tabIndex={-1}>
                                        <TableCell colSpan={7} className="text-center">
                                            Không có dữ liệu
                                        </TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
