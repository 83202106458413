import React, { useState, useEffect } from "react";
import { Configs } from "../../common/config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";

//--- Material Control
import {
    Button,
    TextField,
    IconButton,
    Tooltip,
    Popover,
    Fab,
    FormControlLabel,
    Switch,
    ButtonGroup
} from "@material-ui/core";

//--- Material Icon
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
import DeleteCircle from "@material-ui/icons/DeleteForever"

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as categoryParentAction from "../../redux/store/category-parent/category-parent.store";
import ListCategoryParent from "./list-category-parent/list-category-parent.view";
import EditCategoryParent from "./edit-category-parent/edit-category-parent.view";
import AddCategoryParent from "./add-category-parent/add-category-parent.view";
import * as appActions from "../../core/app.store";

function CategoryParent(props) {
    const { isLoading, showLoading } = props;
    const [categoryParentModels, setCategoryParentModels] = useState();
    const [totalItemCount, setTotalItemCount] = useState();
    const [name, setName] = useState();
    const [isDelete, setIsDelete] = useState();
    const [isShowTrash, setIsShowTrash] = useState(false);
    const [categoryParentId, setCategoryParentId] = useState();
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("modifiedDate");
    const [page, setPage] = useState(0);
    //const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);
    const [rowsPerPage, setRowsPerPage] = useState(2147483647);
    const [status, setStatus] = useState();

    const { register, handleSubmit } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    useEffect(() => {
        //GetListCategoryParent(1, 2147483647);
    }, []);

    const GetListCategoryParent = (
        pageIndex = 1,
        pageSize,
        sortExpression = orderBy + " " + order,
        name,
        isDelete,
        parentId
    ) => {
        tableRef && tableRef.onQueryChange();
    };

    ///list
    const [tableRef, setTableRef] = useState();

    const handleInitList = (ref) => {
        setTableRef(ref);
    };

    //--- Dialog
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openEmptyTrashDialog, setOpenEmptyTrashDialog] = useState(false);

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleOpenEditDialog = (ctParentId) => {
        setCategoryParentId(ctParentId);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleOpenDeleteDialog = (ctParentId, status) => {
        setCategoryParentId(ctParentId);
        setStatus(status);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setOpenEmptyTrashDialog(false);
    };

    //--- Filter
    const [filterSection, setFilterSection] = useState(null);

    const handleClickFilter = (event) => {
        setFilterSection(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setFilterSection(null);
    };

    const ShowNotificationError = (messages) => {
        ShowNotification(messages, NotificationMessageType.Error);
    };

    const openFilter = Boolean(filterSection);
    const idFilter = openFilter ? "popoverSlider" : undefined;

    const handleClearAllField = () => {
        setName("");
        document.getElementById("formSearch").reset();
        //GetListCategoryParent(1, rowsPerPage, orderBy + " " + order, "", isDelete);
        tableRef && tableRef.onQueryChange();
        handleCloseFilter();
    };

    const onSubmit = async (data) => {
        //await GetListCategoryParent(1, rowsPerPage, orderBy + " " + order, name, isDelete);
        tableRef && tableRef.onQueryChange();
        handleCloseFilter();
    };

    const refresh = () => {
        setName("");
        setOrderBy("modifiedDate");
        setOrder("desc");
        //GetListCategoryParent(1, rowsPerPage, orderBy + " " + order);
        tableRef && tableRef.onQueryChange();
    };

    const handleDelete = () => {
        categoryParentAction.DeleteCategoryParent(categoryParentId).then(
            (res) => {
                if (res && res.content && res.content.status) {
                    GetListCategoryParent(
                        1,
                        rowsPerPage,
                        orderBy ? orderBy + " " + order : "",
                        name,
                        isDelete
                    );
                    handleCloseDeleteDialog();
                    ShowNotification(
                        viVN.Success.DeleteCategoryParent,
                        NotificationMessageType.Success
                    );
                }
            },
            (err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
            }
        );
    };

    const handleRestore = (ctParentId) => {
        categoryParentAction.RestoreCategoryParent(ctParentId).then(
            (res) => {
                if (res && res.content && res.content.status) {
                    tableRef.onQueryChange();
                    ShowNotification(
                        viVN.Success.RestoreCategoryParent,
                        NotificationMessageType.Success
                    );
                }
            },
            (err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
            }
        );
    };

    const handleChangeTrash = (event) => {
        setIsShowTrash(event.target.checked);
        setIsDelete(event.target.checked ? 1 : 0);
        //GetListCategoryParent(1, rowsPerPage, orderBy + " " + order, name, (event.target.checked ? 1 : 0));
        tableRef && tableRef.onQueryChange();
    };

    const handleOpenEmptyTrashDialog = () => {
        setOpenEmptyTrashDialog(true);
        console.log("handleOpenEmptyTrashDialog");
    };

    const handleEmptyTrash = () => {
        console.log("handleEmptyTrash");
        categoryParentAction.EmptyTrash().then(
            (res) => {
                if (res && res.content && res.content.status) {
                    tableRef && tableRef.onQueryChange();
                    //GetListCategoryParent(1, rowsPerPage, orderBy + " " + order, name, isDelete);
                    handleCloseDeleteDialog();
                    ShowNotification(
                        viVN.Success.EmptyTrashCategoryParent,
                        NotificationMessageType.Success
                    );
                }
            },
            (err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
            }
        );
    }

    return (
        <div className="slider">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <h1 className="h3 mb-0 text-gray-800">
                    Quản lý nhóm layer
          <Tooltip title="Tìm kiếm">
                        <Fab
                            color="primary"
                            aria-label="filter"
                            size="small"
                            className="ml-2"
                            aria-describedby={idFilter}
                            onClick={handleClickFilter}
                        >
                            <FilterListIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Refresh">
                        <Fab
                            color="primary"
                            aria-label="filter"
                            size="small"
                            onClick={refresh}
                            className="ml-2"
                        >
                            <RefreshIcon />
                        </Fab>
                    </Tooltip>
                    <span>&nbsp;&nbsp;</span>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={isShowTrash}
                                onChange={handleChangeTrash}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Thùng rác"
                    />
                    <Popover
                        id={idFilter}
                        open={openFilter}
                        anchorEl={filterSection}
                        onClose={handleCloseFilter}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >
                        <div className="p-3" style={{ width: "30rem" }}>
                            <div className="text-right border-bottom mb-3 pb-2">
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    onClick={handleCloseFilter}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </div>
                            <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label className="text-dark">Tên quận - huyện</label>
                                    <TextField
                                        className="w-100"
                                        name="name"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        inputRef={register}
                                    />
                                </div>
                                <div className="border-top">
                                    <div className="row">
                                        <div className="col-12 text-right mt-3">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={onSubmit}
                                            >
                                                <SearchIcon fontSize="small" /> Tìm kiếm
                      </Button>
                                            <Button
                                                variant="contained"
                                                className="ml-2"
                                                onClick={handleClearAllField}
                                            >
                                                <ClearAllIcon fontSize="small" /> Bỏ lọc
                      </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Popover>
                </h1>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">

                    {
                        isShowTrash && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleOpenEmptyTrashDialog}
                                startIcon={<DeleteCircle />}>
                                Xóa thùng rác
                            </Button>
                        )
                    }
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenAddDialog}
                        startIcon={<AddCircle />}
                    >
                        Thêm nhóm layer
                    </Button>
                </ButtonGroup>
                
            </div>
            <ListCategoryParent
                totalItemCount={totalItemCount}
                name={name}
                isDelete={isDelete}
                editAction={handleOpenEditDialog}
                deleteAction={handleOpenDeleteDialog}
                restoreAction={handleRestore}
                handleInitList={handleInitList}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
            />
            {//categoryParentModels ? (
            //    <ListCategoryParent
            //        totalItemCount={totalItemCount}
            //        //categoryParentModels={categoryParentModels}
            //        GetListCategoryParent={GetListCategoryParent}
            //        name={name}
            //        isDelete={isDelete}
            //        editAction={handleOpenEditDialog}
            //        deleteAction={handleOpenDeleteDialog}
            //        restoreAction={handleRestore}
            //        setOrder={setOrder}
            //        setOrderBy={setOrderBy}
            //        setPage={setPage}
            //        setRowsPerPage={setRowsPerPage}
            //        order={order}
            //        orderBy={orderBy}
            //        page={page}
            //        rowsPerPage={rowsPerPage}
            //    />
            //) : (
            //        ""
                //    )
            }

            {openAddDialog && (
                <AddCategoryParent
                    isOpen={openAddDialog}
                    onClose={handleCloseAddDialog}
                    onSuccess={handleCloseAddDialog}
                    categoryParentModels={categoryParentModels}
                    ShowNotificationError={ShowNotificationError}
                    GetListCategoryParent={GetListCategoryParent}
                    categoryParentModels={categoryParentModels}
                    rowsPerPage={rowsPerPage}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    showLoading={showLoading}
                />
            )}

            {openEditDialog && (
                <EditCategoryParent
                    isOpen={openEditDialog}
                    onClose={handleCloseEditDialog}
                    onSuccess={handleCloseEditDialog}
                    categoryParentId={categoryParentId}
                    categoryParentModels={categoryParentModels}
                    ShowNotificationError={ShowNotificationError}
                    GetListCategoryParent={GetListCategoryParent}
                    rowsPerPage={rowsPerPage}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    showLoading={showLoading}
                />
            )}

            {openDeleteDialog ? (
                <DeleteDialog
                    isOpen={openDeleteDialog}
                    rowsPerPageCommon={rowsPerPage}
                    onClose={handleCloseDeleteDialog}
                    onSuccess={handleDelete}
                />
            ) : (
                    ""
                )}
            {openEmptyTrashDialog ? (
                <DeleteDialog
                    isOpen={openEmptyTrashDialog}
                    rowsPerPageCommon={rowsPerPage}
                    onClose={handleCloseDeleteDialog}
                    onSuccess={handleEmptyTrash}
                />
            ) : (
                    ""
                )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CategoryParent);
