import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Configs } from "../../common/config";
import { useForm } from "react-hook-form";

//--- Material Control
import {
    Button,
    TextField,
    IconButton,
    Tooltip,
    Popover,
    Select,
    MenuItem,
    Checkbox,
    Fab,
} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ButtonGroup from '@material-ui/core/ButtonGroup';

//--- Styles
import "./news.scss";

//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever"
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from '@material-ui/icons/Refresh';
import CancelIcon from '@material-ui/icons/Cancel';

//--- Component
import ListNews from "./list-news/list-news.view";
import AddNews from "./add-news/add-news.view";
import EditNews from "./edit-news/edit-news.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType} from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as newsAction from "../../redux/store/news/news.store";
import * as appActions from "../../core/app.store";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function News(props) {
    const {
        newsModels,
        GetListAll,
        GetDetail,
        Delete,
        Search,
        ChangeShowTrash,
        TrashEmpty,
        ClearDetail,
    } = props;
    const classes = useStyles();
    const [searchData, setSearchData] = useState({
        "title": "",
        "description": "",
        "status":2
    });
    const [newsId, setNewsId] = useState();
    const [newsxmin, setNewsxmin] = useState();
    const [isShowTrash, setIsShowTrash] = useState(false);

    const { register, handleSubmit } = useForm({ mode: "all", reValidateMode: "onBlur" });

    useEffect(() => {
        GetListAll();
    }, [])

    //--- Dialog
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleOpenEditDialog = (newsId) => {
        setNewsId(newsId);
        GetDetail(newsId);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        ClearDetail();
        setOpenEditDialog(false);
    };

    const handleOpenDeleteDialog = (newsId, newsxmin) => {
        setNewsId(newsId);
        setNewsxmin(newsxmin);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    //--- Filter
    const [filterSection, setFilterSection] = useState(null);

    const handleClickFilter = (event) => {
        setFilterSection(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setFilterSection(null);
    };

    const ShowNotificationError = (messages) => {
        ShowNotification(messages, NotificationMessageType.Error)
    }

    const openFilter = Boolean(filterSection);
    const idFilter = openFilter ? 'popoverSlider' : undefined;
    const handleDelete = () => {
        Delete(newsId, newsxmin);
        handleCloseDeleteDialog();
    };

    const handleClearAllField = () => {
        refresh();
        setFilterSection(null);
    }

    const onSubmit = (data) => {
        Search(searchData);
        handleCloseFilter();
    }

    const refresh = () => {
        let searchdt = {
            "title": "",
            "description": "",
            "status": 2
        };
        setSearchData(searchdt);
        Search(searchdt);
    }

    const handleChangeTrash = (event) => {
        setIsShowTrash(event.target.checked);
        ChangeShowTrash(event.target.checked ? 1 : 0);
    };

    const handleChangeSearchForm = (event) =>
    {
        setSearchData({...searchData, [event.target.name]: event.target.value });
    }

    return (
        <div className="slider">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <h1 className="h3 mb-0 text-gray-800">
                    Tin tức
          <Tooltip title="Tìm kiếm">
                        <Fab
                            color="primary"
                            aria-label="filter"
                            size="small"
                            className="ml-2"
                            onClick={handleClickFilter}
                            aria-describedby={idFilter}
                        >
                            <SearchIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Làm mới">
                        <Fab color="primary" aria-label="filter" size="small" onClick={refresh} className="ml-2">
                            <RefreshIcon />
                        </Fab>
                    </Tooltip>
                    <span>&nbsp;&nbsp;</span>
                    
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isShowTrash}
                                onChange={handleChangeTrash}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Thùng rác"
                    />
                    <Popover
                        id={idFilter}
                        open={openFilter}
                        anchorEl={filterSection}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                        onClose={handleCloseFilter}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <div className="p-3" style={{ width: '30rem' }}>
                            <div className="text-right border-bottom mb-3 pb-2">
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    onClick={handleCloseFilter}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </div>
                            <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label className="text-dark">Tiêu đề</label>
                                    <TextField className="w-100" name="title" value={searchData.title} onChange={handleChangeSearchForm} inputRef={register} />
                                </div>
                                <div className="form-group">
                                    <label className="text-dark">Mô tả</label>
                                    <TextField className="w-100" name="description" value={searchData.description} onChange={handleChangeSearchForm} inputRef={register} />
                                </div>
                                <div className="form-group">
                                    <label className="text-dark">Trạng thái</label> &nbsp;&nbsp;
                                    <Select
                                        name="status"
                                        value={searchData.status}
                                        onChange={handleChangeSearchForm}
                                    >
                                        <MenuItem value={2}>----------------</MenuItem>
                                        <MenuItem value={1}>Kích hoạt</MenuItem>
                                        <MenuItem value={0}>Không kích hoạt</MenuItem>
                                    </Select>
                                </div>
                                <div className="border-top">
                                    <div className="row">
                                        <div className="col-12 text-right mt-3">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={onSubmit}
                                            >
                                                <SearchIcon fontSize="small" /> Tìm kiếm
                      </Button>
                                            <Button variant="contained" className="ml-2" onClick={handleClearAllField}>
                                                <ClearAllIcon fontSize="small" /> Bỏ lọc
                      </Button>
                                            <Button variant="contained" className="ml-2" color="secondary" onClick={handleCloseFilter}>
                                                <CancelIcon fontSize="small" /> Đóng
                      </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Popover>
                </h1>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">

                    {
                        isShowTrash && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={TrashEmpty}
                                startIcon={<DeleteCircle />}>
                                Xóa thùng rác
                            </Button>
                            )
                    }
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenAddDialog}
                        startIcon={<AddCircle />}>
                        Thêm Tin
                    </Button>
                </ButtonGroup>
            </div>
            {newsModels ? <ListNews
                editAction={handleOpenEditDialog}
                deleteAction={handleOpenDeleteDialog}
            /> : ""}
            {openAddDialog && (
                <AddNews
                    isOpen={openAddDialog}
                    onClose={handleCloseAddDialog}
                    onSuccess={handleCloseAddDialog}
                    ShowNotificationError={ShowNotificationError}
                />
            )}

            {openEditDialog && (
                <EditNews
                    isOpen={openEditDialog}
                    onClose={handleCloseEditDialog}
                    onSuccess={handleCloseEditDialog}
                    newsId={newsId}
                    //newsDetail={newsDetail}
                    ShowNotificationError={ShowNotificationError}                />
            )}

            {openDeleteDialog && (
                <DeleteDialog
                    isOpen={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    onSuccess={handleDelete}
                    header={"Xác nhận xóa"}
                    content={"Bạn có chắc chắn muốn xóa?"}
                />
            )}
        </div>
    );
}

const mapStateToProps = state => ({
    isDelete: state.initNews.isDelete,
    newsModels: state.initNews.newsModels,
    page:state.initNews.pageIndex,
    rowsPerPage: state.initNews.pageSize,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetListAll: newsAction.GetList,
    Restore: newsAction.Restore,
    ChangeStatus: newsAction.ChangeStatus,
    Delete: newsAction.Delete,
    Search: newsAction.Search,
    ChangeShowTrash: newsAction.ChangeShowTrash,
    TrashEmpty: newsAction.TrashEmpty,
    GetDetail: newsAction.GetDetail,
    ClearDetail: newsAction.ClearDetail,
    showLoading: appActions.ShowLoading,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(News)