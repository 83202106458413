import MaterialTable from 'material-table';

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

//--- Material Table
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import dateformat from "dateformat";

import * as categoryParentAction from "../../../redux/store/category-parent/category-parent.store";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: window.outerHeight - 365,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const headCells = [
    { id: "categoryParentName", hideSortIcon: false, label: "Tên nhóm layer" },
    { id: "parent.categoryParentName", hideSortIcon: false, label: "Nhóm layer cấp trên" },
    { id: "color", hideSortIcon: true, label: "Màu" },
    { id: "order", hideSortIcon: false, label: "Sắp xếp" },
    { id: "createdBy", hideSortIcon: false, label: "Người tạo" },
    { id: "createdDate", hideSortIcon: false, label: "Ngày tạo" },
    { id: "modifiedBy", hideSortIcon: false, label: "Người sửa" },
    { id: "modifiedDate", hideSortIcon: false, label: "Ngày sửa" },
    { id: "actions", hideSortIcon: true, label: "" },
];

const columns = 
    [
        { title: 'Tên nhóm layer', field: 'category_parent_name', width: 200, },
        { title: 'Nhóm layer cấp trên', field: 'parent.category_parent_name', sorting:false, width:200 },
        {
            title: 'Màu', field: 'color', render: rowData => <span style={{ background: rowData.color }}>{rowData.color}</span>, sorting: false, width:50},
        { title: 'Sắp xếp', field: 'order', width:80 },
        { field: "created_by", title: "Người tạo", width:200 },
        { field: "created_date", title: "Ngày tạo", render: rowData => dateformat(rowData.created_date, "dd-mm-yyyy") },
        { field: "modified_by", title: "Người sửa", width:200 },
        { field: "modified_date", title: "Ngày sửa", render: rowData => dateformat(rowData.modified_date, "dd-mm-yyyy") },
    ];

const parentChild = (row, rows) => 
    rows.find(a => a.id === row.parentId)
;

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className="pt-3 pb-3 text-nowrap"
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={headCell.hideSortIcon ? true : false}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function  ListCategoryParent(props) {
    const {
        editAction,
        categoryParentModels,
        totalItemCount,
        setOrder,
        setOrderBy,
        setPage,
        setRowsPerPage,
        //GetListCategoryParent,
        name,
        isDelete,
        order,
        page,
        rowsPerPage,
        orderBy,
        deleteAction,
        restoreAction,
        handleInitList,
    } = props;

    const tableRef = React.createRef();
    //--- Config table
    const classes = useStyles();

    //--- Handle sort, change page, change row per page
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        let sort = isAsc ? "desc" : "asc";
        let sortExpression = property + " " + sort;
        //GetListCategoryParent(page + 1, rowsPerPage, sortExpression, name, isDelete);
        tableRef.current.onQueryChange();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        let sortExpression = orderBy + " " + order;
        //GetListCategoryParent(newPage + 1, rowsPerPage, sortExpression, name, isDelete);
        tableRef.current.onQueryChange();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + " " + order;
        //GetListCategoryParent(1, event.target.value, sortExpression, name, isDelete);
        tableRef.current.onQueryChange();
    };

    const handleParentClick = (parent) => {
        if (parent) {
            setPage(0);
            let sortExpression = orderBy + " " + order;
            //GetListCategoryParent(1, rowsPerPage, sortExpression, name, isDelete, parent.id);
            tableRef.current.onQueryChange();
        }
    }

    //const emptyRows =
    //    rowsPerPage -
    //    Math.min(rowsPerPage, categoryParentModels.length - page * rowsPerPage);

    const getListData = (query, resolve) => {
        var sortExpression = "modifiedDate";
        var order ="desc"
        if (query.orderBy) {
            order = query.orderDirection;
            switch (query.orderBy.tableData.columnOrder) {
                case 0:
                    sortExpression = "categoryParentName";
                    break;
                case 1:
                    return;
                case 2:
                    sortExpression = "color";
                    break;
                case 3:
                    sortExpression = "order";
                    break;
                case 4:
                    sortExpression = "createdBy";
                    break;
                case 5:
                    sortExpression = "createdDate";
                    break;
                case 6:
                    sortExpression = "modifiedBy";
                    break;
                case 7:
                    sortExpression = "modifiedDate";
                    break;
            }
        }
        //console.log(query);
        sortExpression = sortExpression + " " + order;
        categoryParentAction
            .GetListCategoryParent(1, 2147483647, sortExpression, name, isDelete)
            .then((res) => {
                tableRef.current && handleInitList(tableRef.current);
                if (res && res.content) {
                    resolve({
                        data: res.content.items,
                        page: 0,
                        totalCount: res.content.totalItemCount,
                    })
                }
            })
            .catch((err) => {
            });
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {/*
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} size="small" stickyHeader>
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={categoryParentModels.length}
                        />
                        <TableBody>
                            {categoryParentModels && categoryParentModels.length > 0 ? (
                                categoryParentModels.map((row, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.id}>
                                            <TableCell>{row.category_parent_name}</TableCell>
                                            <TableCell><a href="#" onClick={() => handleParentClick(row.parent)}>{row.parent && (row.parent.category_parent_name)}</a></TableCell>
                                            <TableCell style={{ background: row.color }}>{row.color}</TableCell>
                                            <TableCell>{row.order}</TableCell>
                                            <TableCell>{row.created_by}</TableCell>
                                            <TableCell>
                                                {row.createdDate
                                                    ? dateformat(row.created_date, "dd-mm-yyyy")
                                                    : null}
                                            </TableCell>
                                            <TableCell>{row.modified_by}</TableCell>
                                            <TableCell>
                                                {row.modifiedDate
                                                    ? dateformat(row.modified_date, "dd-mm-yyyy")
                                                    : null}
                                            </TableCell>
                                            <TableCell align="right" className="text-nowrap">
                                                <Tooltip title="Sửa">
                                                    <IconButton
                                                        aria-label="edit"
                                                        onClick={() => editAction(row.id)}
                                                    >
                                                        <EditIcon className="text-primary" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Xóa">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => deleteAction(row.id)}
                                                    >
                                                        <DeleteIcon className="text-danger" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                    <TableRow hover tabIndex={-1}>
                                        <TableCell colSpan={9} className="text-center">
                                            Không có dữ liệu
                  </TableCell>
                                    </TableRow>
                                )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 0 }}>
                                    <TableCell
                                        colSpan={4}
                                        style={{ padding: 0, borderBottom: 0 }}
                                    />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                */}
                <MaterialTable

                    columns={columns}
                    parentChildData={(row, rows) => parentChild(row, rows)}
                    tableRef={tableRef}
                    data={query => new Promise((resolve, reject) => {
                        getListData(query,resolve);
                    })}
                    options={{
                        pageSize: rowsPerPage,
                        pageSizeOptions: [10, 20],
                        actionsColumnIndex: -1,
                        headerStyle: {

                            fontWeight: true,
                        },
                        search: false,
                        thirdSortClick: false,
                        maxBodyHeight: "400px",
                        toolbar: false,
                        paging: false,
                    }}
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                        {
                            icon: 'edit',
                            iconProps: {
                                color: 'primary'
                            },
                            tooltip: 'Sửa',
                            onClick: (event, rowData) => editAction(rowData.id)
                        },
                        rowData => ({
                            icon: 'delete',
                            iconProps: {
                                color: 'error'
                            },
                            tooltip: 'Xóa',
                            onClick: (event, rowData) => deleteAction(rowData.id),
                            hidden: rowData.has_child
                        }),
                        rowData => ({
                            icon: 'delete',
                            iconProps: {
                                color: 'disabled'
                            },
                            tooltip: rowData.parentId == null ? 'Không thể xóa' : 'Xóa',
                            onClick: (event, rowData) => deleteAction(rowData.id),
                            disabled: rowData.parentId == null,
                            hidden: !rowData.has_child
                        }),
                        rowData => ({
                            icon: 'restore',
                            iconProps: {
                                color: 'action'
                            },
                            tooltip: 'Khôi phục',
                            onClick: (event, rowData) => restoreAction(rowData.id),
                            hidden: rowData.is_delete != 1,
                        })
                    ]}
                />
                {/*totalItemCount && totalItemCount > 0 ? (
                    <TablePagination
                        rowsPerPageOptions={Configs.DefaultPageSizeOption}
                        component="div"
                        count={totalItemCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={"Số hàng mỗi trang"}
                        labelDisplayedRows={({ from, to, count }) => {
                            return "" + from + "-" + to + " trong " + count || "";
                        }}
                    />
                ) : (
                        ""
                    )*/}
            </Paper>
            <div style={{ maxWidth: '100%'}}>
                
            </div>
        </div>
    );
}
