/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//--- Action
import * as categoryParentAction from "../../../redux/store/category-parent/category-parent.store";

//--- Material Control
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    makeStyles,
    Typography,
    IconButton,
    Select,
    MenuItem,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

export default function EditCategoryParent(props) {
    const classes = useStyles();

    const {
        isOpen,
        onClose,
        onSuccess,
        categoryParentId,
        setOrder,
        setOrderBy,
        GetListCategoryParent,
        rowsPerPage,
        showLoading,
        categoryParentModels,
    } = props;

    const [categoryParentModel, setCategoryParentModel] = useState();
    const [parentId, setParentId] = useState();
    const [listCategoryParent, setListCategoryParent] = useState();

    useEffect(() => {
        showLoading(true);
        categoryParentAction
            .GetListCategoryParent(1, 2147483647, "", "", 0)
            .then((res) => {
                if (res && res.content) {
                    let listparent = res.content.items.filter(x => !x.parent);
                    listparent.push({ id: 0, category_parent_name: "Không chọn cấp trên" });
                    setListCategoryParent(listparent);
                }
            })
            .catch((err) => {
            });

        categoryParentAction
            .GetDetailCategoryParent(categoryParentId)
            .then((res) => {
                if (res && res.content) {
                    setCategoryParentModel(res.content);
                    setParentId(res.content.parentId);
                    showLoading(false);
                    console.log(res.content);
                }
            })
            .catch((err) => {
                showLoading(false);
            });
    }, []);

    const { register, handleSubmit, errors, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
            return;
        }
        console.log(data);
        categoryParentAction
            .UpdateCategoryParent({ ...data, parentId: parentId, id: categoryParentId })
            .then((result) => {
                if (result) {
                    setOrder("desc");
                    setOrderBy("modifiedDate");
                    GetListCategoryParent(1, rowsPerPage);
                    onSuccess();
                    ShowNotification(
                        viVN.Success.EditCategoryParent,
                        NotificationMessageType.Success
                    );
                }
            })
            .catch((err) => {
                onSuccess();
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            });
    };

    const handleChangeSelect = (event) => {
        console.log(event.target.value);
        setParentId(event.target.value);
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Chỉnh sửa nhóm layer</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    {categoryParentModel && (
                        <DialogContent className="pt-4 pb-2">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <label className="text-dark">
                                            Tên nhóm layer<span className="required"></span>
                                        </label>
                                        <TextField
                                            type="text"
                                            name="categoryParentName"
                                            className="w-100"
                                            inputProps={{ maxLength: 150 }}
                                            inputRef={register({ required: true })}
                                            defaultValue={categoryParentModel.category_parent_name}
                                            error={errors.categoryParentName && errors.categoryParentName.type === "required"}
                                        />
                                        {errors.categoryParentName && errors.categoryParentName.type === "required" && (
                                            <span className="error">Trường này là bắt buộc</span>
                                        )}
                                        {errors.categoryParentName && errors.categoryParentName.type === "maxLength" && (
                                            <span className="error">Trường này không quá 150 ký tự</span>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <label className="text-dark">
                                            Nhóm layer cấp trên<span className="required"></span>
                                        </label>
                                        <br />
                                        <Select
                                            className="w-100"
                                            defaultValue={categoryParentModel.parentId}
                                            onChange={(e) => {
                                                handleChangeSelect(e);
                                            }}
                                        >
                                            {listCategoryParent && listCategoryParent.length > 0 ? (
                                                listCategoryParent.map((item, index) => (
                                                    <MenuItem key={index} value={item.id}>
                                                        {item.category_parent_name}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                    <MenuItem value="">Không có nhóm layer nào</MenuItem>
                                                )}
                                        </Select>
                                        {errors.provice && errors.provice.type === "required" && (
                                            <span className="error">Trường này là bắt buộc</span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <label className="text-dark">
                                            Màu<span className="required"></span>
                                        </label>
                                        <TextField
                                            type="text"
                                            name="color"
                                            className="w-100"
                                            inputRef={register}
                                            defaultValue={categoryParentModel.color}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <label className="text-dark">
                                            Sắp xếp<span className="required"></span>
                                        </label>
                                        <TextField
                                            type="text"
                                            name="order"
                                            className="w-100"
                                            defaultValue={categoryParentModel.order}
                                            onChange={(e) =>
                                                setValue(
                                                    "order",
                                                    e.target.value.replace(/[^0-9]/g, "")
                                                )
                                            }
                                            inputRef={register({ required: true })}
                                            error={
                                                errors.order && errors.order.type === "required"
                                            }
                                        />
                                        {errors.order && errors.order.type === "required" && (
                                            <span className="error">Trường này là bắt buộc</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    )}

                    <DialogActions className="border-top">
                        <Button
                            type="button"
                            onClick={onClose}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
            </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Lưu
            </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
